import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import FvSec from "../components/fvSec";
import Pagenation from "../components/pagenation";

import linkArrow from "../images/linkArrow.svg";

const AllNewsPage = ({ data, pageContext }) => {
  const { homepage } = data.siteMetaData;
  const newses = data.allNews.edges;
  const totalCount = data.allNews.totalCount;

  return (
    <Layout {...homepage}>
      <Seo title="What's New" />
      <FvSec page="allNews" />
      <section css={container}>
        <ul css={newsUl}>
          {newses.map(({ node }) => (
            <li key={node.id}>
              <time dateTime={node.date}>{node.date}</time>
              <a href={node.link}>
                <p>{node.title}</p>
              </a>
            </li>
          ))}
        </ul>
      </section>
      <Pagenation pageContext={pageContext} totalCount={totalCount} />
    </Layout>
  );
};

export default AllNewsPage;

const container = css`
  max-width: 76%;
  margin: 85px auto 0;
  @media (max-width: 767px) {
    max-width: 100%;
    margin: 50px auto 0;
    padding: 0 15px;
  }
`;

const newsUl = css`
  li {
    border-top: 1px solid rgba(157, 167, 181, 0.5);
    padding: 20px 0;
    display: flex;
    position: relative;

    &:last-child {
      border-bottom: 1px solid rgba(157, 167, 181, 0.5);
    }

    time {
      font-weight: normal;
      width: 130px;
      flex: none;
    }

    a {
      padding-right: 10px;
      display: block;
    }

    p {
      &::after {
        content: "";
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-left: 7px;
        background-image: url(${linkArrow});
        background-size: 10px 10px;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    @media (max-width: 767px) {
      display: block;
      padding: 12px 0;

      time {
        font-size: 14px;
      }

      p {
        line-height: 1.5;
      }
    }
  }
`;

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    siteMetaData: site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    allNews: allWpPost(
      sort: { fields: date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      edges {
        node {
          date(formatString: "YYYY.MM.DD")
          link
          title
          id
        }
      }
    }
  }
`;
