import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";

import linkArrow from "../images/linkArrow.svg";
import "@fontsource/arvo";

const Pagenation = ({ totalCount, pageContext }) => {
  const NEWS_PER_PAGE = 20;
  const { previousPagePath, nextPagePath } = pageContext;

  // pagenation num list amount
  const range = (start, end) =>
    [...Array(end - start + 1)].map((_, i) => start + i);

  return (
    <ul css={pagerContainer}>
      {previousPagePath && (
        <li className="prev">
          <Link to={previousPagePath}>
            <img src={linkArrow} alt="" />
          </Link>
        </li>
      )}
      {range(1, Math.ceil(totalCount / NEWS_PER_PAGE)).map((number, index) => (
        <li key={index}>
          <Link
            to={`/all-news${number === 1 ? "" : "/" + number}`}
            activeStyle={{ textDecoration: "underline" }}
          >
            {number}
          </Link>
        </li>
      ))}
      {nextPagePath && (
        <li className="next">
          <Link to={nextPagePath}>
            <img src={linkArrow} alt="" />
          </Link>
        </li>
      )}
    </ul>
  );
};

export default Pagenation;

const pagerContainer = css`
  margin: 120px auto 140px;
  display: flex;
  justify-content: center;
  gap: 40px;
  font-family: "Arvo";
  text-align: center;
  font-size: 20px;

  .prev {
    transform: scale(-1, 1);
  }

  @media (max-width: 767px) {
    margin: 50px auto 90px;
  }
`;
